<template>
  <div class="">
    <h1>Debug</h1>

    <p v-if="!data">Loading...</p>

    <div v-if="prodNoParent.length">
      <h2>Textes produit sans parent</h2>
      <p>Si jamais c'est pas volontaire, ceux-ci risquent de n'être jamais accessibles</p>

      <ul>
        <li v-for="node in prodNoParent" :key="node.id">
          TextProd n°{{ node.id }}
          <b-button
            variant="kit" class="btn-edit"
            :href="node.link" target="_blank"
          >
            Éditer
          </b-button>
        </li>
      </ul>
    </div>

    <div v-if="noTitles.length">
      <h2>Textes de référence sans titre ou sans auteur⋅ices</h2>

      <ul>
        <li v-for="node in noTitles" :key="node.id">
          TextRef n°{{ node.id }} (manque: {{ node.miss }})
          <b-button
            variant="kit" class="btn-edit"
            :href="node.link" target="_blank"
          >
            Éditer
          </b-button>
        </li>
      </ul>
    </div>

    <div v-if="dupps.length">
      <h2>Textes de référence avec enfants en doublons</h2>
      <p>Ceux-ci sembleraient venir du fait qu'un texte de référence ai été renseigné dans le champs Texte Produits, et automagiquement duppliqué dans le champs Création</p>
      <p>Il est actuellement nécessaire de supprimer les 2 références du texte (dans Textes Produits et dans Créations) car un texte de référence ne peut être défini comme un texte produit</p>

      <ul>
        <li v-for="dupp in dupps" :key="dupp.id">
          Textref n°{{ dupp.id }} contient les doublons suivants: {{ dupp.nodes }}
          <b-button
            variant="kit" class="btn-edit"
            :href="dupp.link" target="_blank"
          >
            Éditer
          </b-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DebugQuery from '@/api/queries/debug.gql'
import api from '@/api'

export default {
  name: 'Page',

  data () {
    return {
      data: undefined,
      dupps: [],
      noTitles: [],
      prodNoParent: []
    }
  },

  async created () {
    const data = (await api.query(DebugQuery)).allmapitems
    data.forEach((node) => {
      const link = `/api/node/${node.id}/edit?destination=/api/node/${node.id}`
      if (node.type === 'Textref') {
        if (node.field_creations) {
          const children = [...node.field_creations, ...(node.text_produits || [])]
          const dupps = []
          const uniqueChildren = new Set()
          children.forEach(({ id }) => {
            if (uniqueChildren.has(id)) {
              dupps.push(id)
            }
            uniqueChildren.add(id)
          })
          if (dupps.length) {
            this.dupps.push({ id: node.id, nodes: dupps, link })
          }
        }
        const miss = {
          titre: !node.field_titre_regular && !node.field_titre_italique,
          'auteur⋅ices': !node.auteurs
        }
        if ((!node.field_titre_regular && !node.field_titre_italique) || !node.auteurs) {
          this.noTitles.push({ ...node, link, miss: Object.keys(miss).filter(key => miss[key]) })
        }
      }
      if (node.type === 'Textprod') {
        if (!node.text_de_depart || node.text_de_depart.length < 1) {
          this.prodNoParent.push({ ...node, link })
        }
      }
    })
    this.data = data
  }
}
</script>

<style lang="scss" scoped>
</style>
